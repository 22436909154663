<template>
  <base-layout title="Dues">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-list>
            <ion-item
              :router-link="{ name: p.routeName }"
              v-for="(p, i) in appPages"
              :key="i"
              class="link"
            >
              <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>
                <h2>{{ p.title }}</h2>
                <p>{{ p.description }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";

import { bookOutline, bookSharp } from "ionicons/icons";

export default {
  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
  },
  data() {
    return {
      appPages: [
        {
          title: "Chargeables",
          description: "Create and modify chargeable items",
          routeName: "chargeable",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Fees",
          description: "Create and modify fees",
          routeName: "fee",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Billing",
          description: "Generate Invoices from Fees",
          routeName: "bill",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Invoices",
          description: "Search Invoices",
          routeName: "invoice",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
